.App {
    /* width: 50%; */
    display: flex;
    /* font-size: 5px; */
    /* align-items: center; */
    justify-content: center;
    /* height: 100vh; */
}

.list-container {
    height: max-content;
    /* list-style: none; */
    /* Elimina los marcadores de la lista */
    width: 100%;
    display: flex;
    /* font-size: 18px; */
    background-color: #dbdbdb;
    flex-direction: column;
}

.item-container {
    /* z-index: 0; */
    /* list-style: none; */
    /* Elimina los marcadores de la lista */
    background-color: #f1efefd5;
    /* border: 1px solid #ada7a726; */
    /* padding: 2px 2px;
    margin: 2px 2px; */
    border-style: dashed;
    border-color: #7e79798f;
    border-width: 0.5px
}